import {mapGetters} from "vuex";

export default {
    name: 'home-header-component',
    props: {
        title: {
            type: String,
            require: true,
        },
        description: {
            type: String,
            require: true,
        },
        statistic: {
            type: Object,
            require: true,
        }
    },
    computed: {
        statistics() {
            return [
                {
                    id: 1,
                    count: this.statistic.projectCount,
                    name: this.local.mainPage.finishedProject
                },
                {
                    id: 2,
                    count: this.statistic.buildingCount,
                    name: this.local.mainPage.finishedBuilding
                },
                {
                    id: 3,
                    count: this.statistic.customerCount,
                    name: this.local.mainPage.customer
                },];
        },
        ...mapGetters({
            local: '_getLocal'
        }),
    },
    created() {

    }
};
