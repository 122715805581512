<style lang="scss" src="./home.component.scss"></style>
<template>
  <div class="home">
    <div class="row">
      <div class="col-lg-6 col-12">
        <home-header-component
            :title="leadHomeHeader.title"
            :description="leadHomeHeader.description"
            :statistic="statistic"
        />
      </div>
      <div class="col-lg-6 col-12">
        <home-banner-component :img="leadHomeHeader.fileUrl"/>
      </div>
      <div class="col-12">
        <home-about-component
            :title="leadWorks.title"
            :description="leadWorks.description"/>
        <home-info-component
            :file="leadWorks.fileUrl"
            :cards="workSkills"/>
      </div>
    </div>
  </div>
</template>
<script src="./home.component.js"></script>
