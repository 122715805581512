import {mapGetters} from "vuex";

export default {
    name: 'home-about-component',
    props: {
        title: {
            type: String,
            require: true
        },
        description: {
            type: String,
            require: true
        }
    },
    computed: {
        ...mapGetters({
            local: '_getLocal'
        }),
    }

}
