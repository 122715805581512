import HomeCardComponent from
        '@/views/home/components/home-card/home-card.component.vue';

export default {
    name: 'home-info-component',
    components: {
        HomeCardComponent,
    },
    props: {
        cards: {
            type: Array,
        },
        file: {
            type: String,
            require: true,
        }
    },
};
