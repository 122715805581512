<style lang="scss" src="./home-about.component.scss"></style>
<template>
  <div class="home-about mb-54">
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="home-about__text text">
          <span
              v-for="(item, index) in title?.split(' ')"
              :key="index">{{ item + ' ' }}
        </span>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="home-about__action">
          <div class="home-about__description">
           {{description}}
          </div>
          <div class="home-about__button">
            <router-link to="about">
              <button class="btn btn--full">{{local.mainPage.about}}</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./home-about.component.js"></script>
