<style lang="scss" src="./home-info.component.scss"></style>
<template>
  <div class="home-info">
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="home-info__image">
          <img :src="file" alt="">
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="home-info__content">
          <home-card-component
              v-for="(crd, index) in cards"
              :key="crd.id"
              :card="crd"
              :number="index +1"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./home-info.component.js"></script>
