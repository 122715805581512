import {mapGetters} from 'vuex';
import HomeHeaderComponent from '@/views/home/components/home-header/home-header.component.vue';
import HomeBannerComponent from '@/views/home/components/home-banner/home-banner.component.vue';
import HomeAboutComponent from '@/views/home/components/home-about/home-about.component.vue';
import HomeInfoComponent from '@/views/home/components/home-info/home-info.component.vue';

export default {
    name: 'home-component',
    components: {
        HomeHeaderComponent,
        HomeBannerComponent,
        HomeAboutComponent,
        HomeInfoComponent,
    },
    computed: {
        ...mapGetters({
            leadHomeHeader: 'home/_getLeadHomeHeader',
            leadWorks: 'home/_getLeadWork',
            statistic: 'home/_getStatistic',
            workSkills: 'home/_getFeatures',
        }),
    },
    created() {
        this.$store.dispatch('home/getStatistic');
        this.$store.dispatch('home/getFeatures');

    }
};
