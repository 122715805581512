export default {
    name: 'home-card-component',
    props:{
        card:{
            type:Object,
            require:true,
        },
        number:{
            type:Number,
            require:true,
        }
    }
}
