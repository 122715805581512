<style lang="scss" src="./home-header.component.scss"></style>
<template>
  <div class="home-header">
    <h1 class="text">
        <span
            v-for="(item, index) in title?.split(' ')"
            :key="index"
            class="text">{{ item + ' ' }}
        </span>
    </h1>
    <h2 class="text mt-20">
      {{ description }}
    </h2>
    <div class="btn-group">
      <router-link :to="{name:'about'}">
        <button class="btn btn--full mr-32 mt-40">{{local.mainPage.buttonDiscover}}</button>
      </router-link>
      <router-link :to="{name:'contact'}">
        <button class="btn">{{local.mainPage.buttonContact}}</button>
      </router-link>
    </div>
    <div class="home-header__statistic mt-56">
      <div
          v-for="sts in statistics"
          :key="sts.id"
          class="home-header__statistic-group">
        <div class="home-header__statistic-count">
          {{ sts.count }}
        </div>
        <div class="home-header__statistic-name mt-8">
          {{ sts.name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./home-header.component.js"></script>
