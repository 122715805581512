<style lang="scss" src="./home-card.component.scss"></style>
<template>
  <div class="home-card">
    <div class="home-card__number">
      {{`0${number}`}}
    </div>
    <div class="home-card__description">
      {{card.title}}
      {{card.description}}
    </div>
  </div>
</template>
<script src="./home-card.component.js"></script>
